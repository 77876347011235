





































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { namespace } from 'vuex-class';
import GUUID from '@/utils/GUUID';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonBookmark extends VueBaseActionButtonWidget {
  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @Prop({ required: true })
  protected readonly actionResult!: string | null;

  @communityUserBookmarkStore.Getter
  private isLoading!: boolean;

  private isBookmarked: string | null = this.actionResult;

  private get text(): string {
    return `${this.isBookmarked ? this.$t('actions.bookmarked') : this.$t('actions.bookmark')}`;
  }

  private get variant(): string {
    return this.isBookmarked ? 'alt-2' : 'secondary';
  }

  private get icon(): string {
    return this.isBookmarked ? 'fa-solid fa-bookmark' : 'fa-regular fa-bookmark';
  }

  private get isDisplayed(): boolean {
    return (this.actionType === this.ActionButtonType.BOOKMARK);
  }

  created(): void {
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  @Watch('actionResult')
  private syncActionResultData(): void {
    this.isBookmarked = this.actionResult;
  }

  private toggleBookmark(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.isBookmarked) {
      const backupValue = this.isBookmarked;
      this.isBookmarked = null;
      this.unBookmark({ uid: backupValue })
        .catch(() => {
          this.isBookmarked = backupValue;
        });
    } else {
      this.isBookmarked = GUUID.uuidv4();
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.entityUid,
        entityType: this.entityType,
      })
        .then((response) => {
          if (response) {
            this.logStats(StatLoggerCategories.BOOKMARK, StatLoggerActions.ADD, '');
            this.isBookmarked = response.uid;
          } else {
            this.isBookmarked = null;
          }
        })
        .catch(() => {
          this.isBookmarked = null;
        });
    }
  }
}
